//api.js
import requestAll from './request.js';
const api = {
    //首页
    homepage(data) {
        return requestAll.postRequest('/api/homepage', data);
    },
    //关于我们
    about(data) {
        return requestAll.postRequest('/api/about', data);
    },
    //子公司
    company_list(data) {
        return requestAll.postRequest('/api/company_list', data);
    },
    //业务领域
    fields_list(data) {
        return requestAll.postRequest('/api/fields_list', data);
    },
    //新闻列表
    news_list(data) {
        return requestAll.postRequest('/api/news_list', data);
    },
    //新闻详情
    news_detail(data) {
        return requestAll.postRequest('/api/news_detail', data);
    },
    //人才招聘
    job(data) {
        return requestAll.postRequest('/api/job', data);
    },
    //提交简历
    job_resume(data) {
        return requestAll.postRequest('/api/job_resume', data);
    },
    //联系我们
    contact_us(data) {
        return requestAll.postRequest('/api/contact_us', data);
    },
    //招聘详情
    job_detail(data) {
        return requestAll.postRequest('/api/job_detail', data);
    },
    //内页banner
    banner_inner(data) {
        return requestAll.postRequest('/api/banner_inner', data);
    },
    //底部信息
    web_info(data) {
        return requestAll.postRequest('/api/web_info', data);
    },
    //子公司详情
    company_detail(data) {
        return requestAll.postRequest('/api/company_detail', data);
    },
    
    

    
    
    
    
    
    
}

export default api
