import Vue from 'vue'
import App from './App.vue'
import router from './router'
import api from './util/api'
import './registerServiceWorker'
import '../static/css/index.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import $ from 'jquery';
import animated from 'animate.css' // npm install animate.css --save安装，在引入
// 引入fullPage
import 'fullpage.js/dist/fullpage.css';
import 'fullpage.js/vendors/scrolloverflow';
import VueFullPage from 'vue-fullpage.js';

import BaiduMap from 'vue-baidu-map'
Vue.use(VueFullPage);
//数字倒计时
import countTo from 'vue-count-to'
Vue.use(countTo)
Vue.prototype.$api = api;
Vue.use(animated);
Vue.use(VueAwesomeSwiper)
Vue.config.productionTip = false

//弹框插件
import layer from "layui-layer";

Vue.use(layer);

//自定义监听滚动指令
// v-loadmore: 用于在element-ui的select下拉框加上滚动到底事件监听
Vue.directive('scroll', {
  bind(el, binding) {
    window.addEventListener('scroll', function() {
      binding.value();
    },true);
  }
})





// 全局获取缓存数据
Vue.prototype.resetSetItem = function (key, newVal) {
  if (key === 'watchStorage') {
      // 创建一个StorageEvent事件
      var newStorageEvent = document.createEvent('StorageEvent');
      const storage = {
          setItem: function (k, val) {
              sessionStorage.setItem(k, val);
              // 初始化创建的事件
              newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
              // 派发对象
              window.dispatchEvent(newStorageEvent)
          }
      }
      return storage.setItem(key, newVal);
  }
},

//地图
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'd4tcuT4wPu6RfeeDWuvt3j7hP7pu8ofj'
})


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
