import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'pubpage',
		redirect:'/',
		component:() => import('../components/pubpage.vue'),
		children:[
			// 首页
			{
				path: '/',
				name: 'home',
				component: () => import('../views/home.vue'),
				meta: { title: '首页' }
			},
			{
				path: '/about/:id',
				name: 'about',
				component: () => import('../views/about/about.vue'),
				meta: { title: '关于我们' }
			},
			{
				path: '/news',
				name: 'news',
				component: () => import('../views/news.vue'),
				meta: { title: '新闻资讯' }
			},
			{
				path: '/newsDetail',
				name: 'newsDetail',
				component: () => import('../views/newsDetail.vue'),
				meta: { title: '新闻详情' }
			},
			{
				path: '/hr',
				name: 'hr',
				component: () => import('../views/hr.vue'),
				meta: { title: '人才招聘' }
			},
			{
				path: '/hrDetails',
				name: 'hrDetails',
				component: () => import('../views/hrDetails.vue'),
				meta: { title: '人才招聘详情' }
			},
			{
				path: '/field',
				name: 'field',
				component: () => import('../views/field.vue'),
				meta: { title: '业务领域' }
			},
			{
				path: '/contact',
				name: 'contact',
				component: () => import('../views/contact.vue'),
				meta: { title: '联系我们' }
			},
			{
				path: '/subsidiray/:id',
				name: 'subsidiray',
				component: () => import('../views/subsidiray.vue'),
				meta: { title: '子公司' }
			},
			{
				path: '/subsidiaryCont',
				name: 'subsidiaryCont',
				component: () => import('../views/subsidiaryCont.vue'),
				meta: { title: '子公司' }
			},
			
		],
	},
	
]

const router = new VueRouter({
//   mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
